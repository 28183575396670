/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const HelpScout = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/helpscout/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'HelpScout Integration',
      title: 'Integrate HelpScout with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Help Scout is a popular help desk software provider. It’s an all-in customer service tool.
          The range of available features includes a shared inbox, in-app messaging, live chat, and
          in-app messaging as well.
        </p>
        <p>
          Of course, Help Scout can be connected to other tools. With our integration, you can open
          session replays from the Help Scout app.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access LiveSession recordings straight from email tickets</li>
          <li>See what the user was doing on the website</li>
          <li>Come up with solutions faster</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Copy and paste a piece of code on your website. See a detailed tutorial in our{' '}
        <a href="/help/help-scout-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(HelpScout);

HelpScout.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
